import React, { useState } from 'react';
import {
  Grid,
  Button,
  TextField,
} from '@mui/material';
import MaterialSelector2 from './MaterialSelector';
import CentralCalls from '../../centralCalls';
const InsertMaterialPreset = ({material_template_oid}) => {
  const [materialModelRow, setMaterialModelRow] = useState(null);
  const [cost, setCost] = useState('');
  const [quantity, setQuantity] = useState('');

  const validateForm = () => {
    return materialModelRow && cost.trim() !== '' && quantity.trim() !== '';
  };

  const onMaterialPresetInsert = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      alert('Please fill out all required fields.');
      return;
    }

    console.log({ materialModelRow, cost, quantity, material_template_oid });
    CentralCalls.upsertData({ material_template_oid, material_room_oid: materialModelRow.material_room_oid, material_model_oid: materialModelRow.material_model_oid, quantity, cost }, 'material_preset', 'insert');
    setMaterialModelRow(null);
    setCost('');
    setQuantity('');
  };

  const handleMaterialChange = (materialRow) => {
    setMaterialModelRow(materialRow);
  };

  return (
    <form onSubmit={onMaterialPresetInsert}>
      <Grid container spacing={2}>
        {/* First row: MaterialSelector2 */}
        <Grid item xs={12}>
          <MaterialSelector2 onMaterialModelChange={handleMaterialChange} required />
        </Grid>

        {/* Second row: Cost and Quantity */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Cost"
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Quantity"
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
          />
        </Grid>

        {/* Insert button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            Insert
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default InsertMaterialPreset;
